let clientName = "Chicco";
let productName = "Chicco";
/* Attention, il y a un clientName non-dynamique dans l'optin */

export const fr = {

	/* pageTitles are the H2 title at the TOP of each page */
	pageTitles: {
		beforeStart: "Cette action n'est pas encore disponible.",
		actionEnded: "Cette action est terminée.",
		maxReached: "Maximum de participations atteint",
		wrongCode: "Mauvais code",
		wrongCode2: "Lien de participation incorrect",
		alreadySend: "Participation déjà envoyée",
		registration: "Inscription",
		confirmation: "Confirmation",
		choiceSurvey: "",
		survey: "Enquête",
		summary: "Récapitulatif",
		maintenance: "Maintenance",
		form1: "Demandez vos 10% de remboursement maintenant\u00A0!",
		form2: "Encore quelques informations…", 
		wait: "Veuillez patienter…",
		instructions: "Comment participer",
		pageError: "Une erreur est survenue",
	},

	/* sectionTitles are titles INSIDE pages */
	/* They should not be arranged by page, because they can move from one to another */
	sectionTitles: {
		wayOfRegistration: "Choisissez un mode d'inscription",
		thanksForRegistration: "Merci pour votre inscription !",
		aboutYou: "Dites-nous qui vous êtes",
		address: "Votre adresse",
		aboutProduct: "À propos de notre produit",
		ticketUpload: "Preuve d'achat",
	},

	/* inputsTexts is a list for every label, placeholder and helpers that Action API don't give. */
	inputsTexts: {
		dropdown : {
			placeholder: "Choisissez une réponse…",
			helper: " ",
		},
		title: {
			label: "Titre",
			placeholder: "Comment pouvons-nous vous appeler ?",
			helper: " ",
		},
		genre: {
			label: "Genre",
			placeholder: "Comment vous définissez-vous ?",
			helper: " ",
		},
		firstname: {
			label: "Prénom",
			placeholder : "Mon prénom est…",
			helper: " ",
		},
		lastname: {
			label: "Nom",
			placeholder : "Mon nom est…",
			helper: " ",
		},
		email : {
			label: "E-mail",
			placeholder: "nom{'@'}exemple.be",
			helper: "Votre e-mail doit comporter un {'@'}"
		},
		phone : {
			label: "Mobile",
			placeholder: "Ex : 0477123456",
			helper: " ",
		},
		iban : {
			label: "IBAN",
			placeholder: "ex : BE00123000456000",
			helper: "Le numéro doit commencer par le code pays (BE/LU/…)"
		},
		VATNumber: {
			label: "Numéro de TVA",
			placeholder: "Ex: BE0123123123",
			helper: "Le numéro doit commencer par le code pays (BE/LU/…)"
		},
		googleAPI:{
			label: "Merci de rechercher votre adresse complète (avec le numéro de maison) afin de pouvoir valider le formulaire."
		},
		street : {
			label: "Rue",
			labelfacture: "Adresse de facturation",
			placeholder: "",
			helper: " "
		},
		houseNumber: {
			label: "Numéro",
			placeholder: "",
			helper: " "
		},
		boxNumber: {
			label: "Boite",
			placeholder: "",
			helper: " "
		},
		postalCode: {
			label: "Code postal",
			placeholder: "",
			helper: " "
		},
		city: {
			label: "Localité",
			placeholder: "",
			helper: " "
		},
		country: {
			label: "Pays",
			placeholder: "Choisissez une réponse…",
			helper: " "
		},
		conditions : {
			part1 : "J'accepte les",
			link: "conditions générales",
			part3: " "
		},
		serialNumber : {
			label: "",
			placeholder: "",
			helper: " "
		},
		product: {
			label: "Quel produit avez-vous acheté ?",
			placeholder: "Choisissez une réponse…",
			helper: " "
		},
		retailer: {
			label: "Où avez-vous acheté le produit ?",
			placeholder: "Choisissez une réponse…",
			helper: " "
		},
		freeRetailer: {
			label: `Dans quelle pharmacie avez-vous acheté vos produits ${productName} ?`,
			placeholder: "…",
			helper: " "
		},
		freeInput: {
			label: ``,
			placeholder: "",
			helper: "",
			error_empty: "Veuillez remplir ce champ",
			error_wrongValue: "La valeur est incorrecte",
		},
		datePurchase : {
			label: `Quand avez-vous acheté vos produits ${productName} ?`,
			placeholder: "jj/mm/aaaa",
			helper: `<strong>ATTENTION :</strong> Veuillez utiliser le calendrier pour introduire la date.`,
		},
		uploadsLabels: {
			ticket: "Cliquez ou déposez votre ticket ici.",
			dropzone1: "Cliquez ou déposez une photo des code-barres ici.",

		},
		optin : {
			part1:"Je souhaite être tenu au courant des promotions ",
			linklabel:`${clientName}`,
			link:"https://www.CLIENT.be/fr/",
			part2:"",
			label : "", /* Should not be visible */
			helper : "",
			complement: `Je souhaite être tenu au courant par e-mails des futures actions de ${clientName}<sup>®</sup>.`,		
		},
	},

	testPanel: {
		title: 'Les pages "cachées"',
		text: "Cliquez sur les boutons ci-dessous pour accéder aux pages du formulaire qui ne font pas partie du flux normal de la participation",
		pages: {
			before: "Avant le début",
			after: "Après la fin",
			maxparticip: "Max. participation",
			wrongcode: "Mauvais code",
			alreadysent: "Déjà envoyé",
			maintenance: "En maintenance",
			loading: "Chargement…",
		},
		hide: "Cachez-moi",
		show: "Montrez-moi",
	},



	/* 
	ABOVE: WHAT MUST BE USED NOW
	BELOW: WHAT WAS USED BEFORE 
	*/

	page404: {
		title: "404",
		text: "Cette page n'existe pas.",
		back: "Retour au formulaire",
	},

	errors: {
		emptyField: "Vous devez donner une réponse",
		fieldMissingValue: "Un ou plusieurs champs ont une valeur erronée ou vide",
		dateInvalid: "La date n'est pas valide",
		dateBefore: "La date ne peut être une date future",
		dateOutOfPeriod: "L'achat doit se faire dans les dates valides pour cette action",
		wrongcnk : "Le code CNK n'est pas correct",
		fieldsMissing: "Impossible d'envoyer la participation car un ou plusieurs champs ne sont pas remplis/valides",
		fieldsMissingTitle: "Champs vides/invalides",
	},


	maintenance: {
		line1 : "Ce site web est actuellement en maintenance et ne peut pas être accédé. Réessayez plus tard.",
		line2 : "Notre équipe fait tout pour que le site soit de nouveau accessible au plus vite.",
		thanks : "Merci de votre compréhension.",
	},

	macaron: {
		big: `100%`,
		small:`remboursé`,
	},

	footer: {
		genterms: "Conditions générales",
		privacy: {
			link: "https://www.chicco.be/fr/informations/donnees-personnelles.html",
			label: "Déclaration de confidentialité",
		}
	},

	gdpr: {
		title: "Avis de confidentialité",
		client: `Artsana Belgium S.A`,
	},

	onetrust: {
		cookie: "Gestion des cookies",
	},

	navigation: {
		doSurvey: "Répondre à l'enquête",
		skipSurvey: "Demander le cashback",
		previous: "Page précédente",
		next: "Page suivante",
		claimCashback: "demander mes 10% de remboursement",
		signup: "M'inscrire !",
		participate: "Participer !",
		toCustomerWebsite: {
			label: "Vers notre site",
			link: ""
		},
		readMoreOnWebsite: "En savoir plus",
	},

	breadcrumbSteps: {
		info: "Comment Participer",
		registration: "Enregistrez-vous",
		store: "Faites vos achats",
		form: "Remplissez le formulaire",
		survey: "Répondez à un questionnaire optionnel",
		cashback: "Recevez votre remboursement",
		summary: "Récapitulatif"
	},

	formHeader: {
		bottomText: `* à la conditions…`,
		macaronText: `remboursés&nbsp;!`,
		mainTitle: "Inscrivez-vous et obtenez votre cashback !"
	},



	pageLitteracy: {
		pageError: {
			intro: "Une erreur est survenue qui empêche le formulaire de fonctionner.",
			solution1 : "Veuillez essayer de recharger le formulaire ou réessayer plus tard.",
			solution2 : `Si l'erreur persiste, prenez contact avec Promolife sur le mail <a href="mailto:infoligne{'@'}promolife.be">infoligne{'@'}promolife.be</a> ou au <a href="tel:3223520398">+32 2 352 03 98</a>`,
		},
		instructions : {
			intro: "Instructions de participation comme dans une HAv1. Pas de breadcrumb ici, sinon double emploi (icones, textes).",
			steps: {
				info : "Page d'information",
				register: "Si vous comptez participer plus tard, inscrivez-vous maintenant, ainsi, vous recevrez un rappel.",
				store: {
					line1a: "Rendez-vous en magasin entre le",
					line1b: "et le",
					line2: "Achetez un produit MARQUEMACHIN.",
					line3: "Conservez bien votre ticket de caisse / facture.",
				},
				form: {
					line1: "Introduisez vos coordonnées complètes.",
					line2: "Sur la preuve d’achat de vos produits MARQUEMACHIN (facture / ticket de caisse), entourez la date, les produits achetés et le prix.",
					line3: "Photographiez ou scannez ensuite votre preuve d’achat dans son entièreté.",
					line4: "Téléchargez cette photo ou ce scan.",
				},
				survey: "Répondez à une enquête optionnelle et recevez un coupon de réduction.",
				cashback: "C’est terminé. Après validation, vous recevrez votre remboursement dans un délai de 6 semaines.",
			}
		},
		maxReached : "Cette action est terminée car elle a atteint son maximum de participations.",
		wrongCode: {
			text: `La clé fournie est erronée. Vous devez utiliser le lien que vous a envoyé ${clientName}<sup>®</sup>.`,
			textregistration: "Mais peut-être souhaitez-vous vous inscrire à l'action ?",
			errorWithReparticipation: `Une erreur est survenue avec votre lien de participation.<br>Veuillez contacter nos équipes techniques en envoyant un email à <a href="mailto:infoligne{'@'}promolife.be">infoligne{'@'}promolife.be</a> en joignant les informations ci-dessous&nbsp;:`
		},
		alreadySend: "Vous avez déjà envoyé votre participation.",
		registration : {
			byRegistering: "En m'inscrivant à cette action, j'accepte ",
			privacyNotice: "la politique en matière de vie privée",
			claimMail : "S'inscrire par e-mail",
			claimWhatsapp: "S'inscrire par Whatsapp",
			claimSms: "S'inscrire par SMS",
			or: "Ou",
			whatsappTitle: "Cliquez sur le bouton Whatsapp",
			whatsappInfo: {
				part1: `Une fenêtre s'ouvrira, vous serez ensuite invité à envoyer le mot`,
				part2: `depuis votre Whatsapp.`
			},
		},
		confirmRegistration: {
			smsLine1 :"Vous allez très vite recevoir un SMS avec tous les détails.",
			smsLine2: "Aller voir !",
			whatsappLine1: "Vérifiez votre téléphone",
			whatsappLine2: "Toutes les informations ont été envoyées par Whatsapp",
			mailLine1: "Vous allez très vite recevoir un e-mail avec tous les détails.",
			mailLine2: "Découvrez cet e-mail !",
		},
		form1: {
			legal: ``,
		},
		choiceSurvey: {
			title:'',
			fillSurveyIncite: "Répondez encore à une enquête et obtenez un e-coupon de 1€ valable sur votre prochain achat",
		},
		survey: {
			title: "Merci d'encore répondre à ces quelques questions",
			about: "Enquête",
		},
		confirmation: {
			title: "Confirmation",
			textes: {
				p1: `Nous vous remercions pour votre participation à l'action ${clientName}.`,
				p2: "Vous recevrez votre remboursement endéans les 8 semaines pour autant que vous ayez rempli les conditions générales.",
				coupon: "Nous avons envoyé votre e-coupon à l'adresse e-mail indiquée. Profitez-en&nbsp;!",
				p3: "Bien à vous,",
				p4: `L'équipe ${clientName}`
			},
		},
		actionEnded: {
			text: "N'hésitez pas à visiter régulièrement notre site pour vous tenir informé des actions futures."
		},
		beforeStart: {
			text: "Vous pourrez y accéder à partir du ",
			shouldBeginSoon: "L'action devrait bientôt commencer. Veuillez revenir plus tard."
		},
	},





	inputLabel: {
		defaultListHelper: "Choisissez une proposition dans la liste",
		mandatoryFields: "Champs obligatoires",
		noResult: "Pas de résultat",





		empty : {
			label: "",
			placeholder: "",
			helper: ""
		},
		cnk1 : {
			label: "Quel est le code CNK du produit Clearblue acheté ?",
			placeholder: "0000-000",
			helper: "Le format est : XXXX-XXX",
			error: "Le code CNK n'est pas correct",
		},



        // https://vue-i18n.intlify.dev/guide/essentials/syntax.html#literal-interpolation



/*
        typePassword : "Indiquez votre mot de passe",
        helpPassword : "Votre mot de passe doit contenir X, Y et Z",
        typeUniqueCode : "Indiquez ici le code visible sur le ticket de caisse",
        */


		upload: {
			state: "statut",
			uploadYourFile: "Envoyer vos fichiers",
			clickOrDrop: "Cliquez ou déposez votre ticket ici.",
			rules: "L'image doit être un fichier de type : JPG, JPEG, PNG, BMP, GIF, SVG, WEBP, PDF",
			remove: "Retirer",
			waiting: "En attente de l'envoi…",
			uploaded: "Envoyé !",
			error: "Echoué",
			uploadsDone: "Upload réussi",
			onlyImages: "Seulement des images sont acceptées.",
			maxSize: "Taille maximum de 20 Mo.",
			uploadFile: "Télécharger le fichier",
			generalError: "Erreur, veuillez ressayer plus tard",
			labels: {
				ticket: "Téléchargez votre ticket de caisse.",
				dropzone1: "Téléchargez une photo des code-barres figurant sur l'emballage des produits achetés que vous aurez préalablement barrés. Attention les chiffres des codes-barres doivent rester visibles.",
			},
		}
	},
}
