export default {
	data () {
		return {

			/* For CLIENT in GDPR, see the translation files */

			/* FIRST PAGE */
			/* usual values: 'landing', 'instructions', 'form1' */
			firstPageOfThisForm: 'form1',

			/* For P&G */
			/* If true, allow OneTrust tag and remove GDPR, Matomo, CookieFirst… */
			/* If false… Don't forget to update the IDs and CODES on mixins "ga" and "cf" for google analytics and cookie first */
			isPnGAction: false, 

			/* SCRIPTS */
			thereIsFloodLightScriptInThisAction: false,

			/* BREADCRUMBS */
			displayBreadCrumbs: true,
			listOfCrumbsForThisAction: ['store', 'form','cashback'],
			 /* INFO Possibles values : 'info', 'registration', 'store', 'form', 'survey', 'summary', 'cashback' */
			
			/* DEFAULT LOCALE */
			/* Useful to force nl_NL instead of nl_BE */
			/* ! See in the i18n.js file */

			/* Allow a coupon in the mail (if survey is filled) */
			aCouponCouldBeSent: false,

			/* Is there a survey at all ?*/
			thereIsASurveyInThisAction: false,

			/* REQUIRED FIELDS to UNLOCK BUTTONS */
			/* List of fields that must be valid for a specific button to unlock */

			/* ! Country must not be required when the google API put the value in the field, as it is not validated by action api then. */
			
			/* TODO: find a way to make action api validate that field */

			mustBeValidForPage: {
				'btn_cashback_on_form1' : ['idTitle', 'firstName', 'lastName', 'email', 'IBAN', 'street1', 'houseNumber', 'postalCode', 'city'],
				'btn_cashback_on_form2' : ['idPOPRetailer', 'file', 'fileBarCode', 'conditions'],
				'btn_cashback_on_survey': ['idLeadTMD1', 'idLeadTMD2', 'idLeadTMD3'],
			},

			/* ? Maybe I can compose this programmatically ? ) */
			allRequiredFields: ['idTitle', 'firstName', 'lastName', 'email', 'IBAN', 'street1', 'houseNumber', 'postalCode', 'city', 'idPOPRetailer', 'file', 'fileBarCode', 'conditions'],

		}
	},
}
